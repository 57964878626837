.matcha {
  background-color: #485731;
}
.taro {
  background-color: #634C91;
}
.classic {
  background-color: #3C3333;
}
.caramel {
  background-color: #875020;
}
.blossom {
  background-color: #C44191;
}
.ivorySilk {
  background-color: #D2BBA2;
}
.sparkle {
  background-color: #D73663;
}
.chicken {
  background-color: #C0392B;
}
.berrymerry {
  background: #c23119;
  background: linear-gradient(90deg, #c23119 22%, #485731 54%);
}
.thai {
  background-color: #CA8346;
}
.kokocaphe {
  background-color: #756E30;
}
.jamong {
  background-color: #FF4D4D;
}
.general {
  background-color: #222222;
}
.generalItem {
  background-color: #C1A97E;
}
@keyframes rotateInCircle {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(90);
  }
  66% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes shakeItUp {
  33% {
    transform: rotate(-15deg);
  }
  66% {
    transform: rotate(35deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
@keyframes upDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInHeaderBackground {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: #000000;
  }
}
@keyframes fadeOutHeaderBackground {
  0% {
    background-color: #000000;
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}
.totalsTable {
  border-radius: 1.2em;
  padding: 2em;
  background-color: #F3F1E4;
  margin: auto;
  color: #47908A;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 896px) {
  .totalsTable {
    margin: auto;
    width: 80%;
    max-width: unset;
  }
}
.totalsTable h3 {
  border-radius: 5px;
  text-transform: uppercase;
}
.totalsTable .totalAmount {
  font-size: 28px;
  margin: 10px;
}
.totalsTable .statsRow {
  border-radius: 5px;
  padding: 1em;
  margin-bottom: 1em;
}
.totalsTable .statsRow span {
  float: right;
  line-height: 2em;
  font-size: 1.2em;
  width: 75%;
  font-weight: bold;
  color: #FFF;
}
.totalsTable .statsRow .countCircle {
  float: left;
  font-size: 1.1rem;
  font-family: Futura;
  font-weight: bold;
  width: 1.2em;
  height: 1.2em;
  padding: 15px;
  margin-left: 15px;
  border-radius: 50%;
  background-color: #FFF;
  color: #222222;
  display: flex;
  justify-content: center;
}
