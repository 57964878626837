.foodContainer {
  display: flex;
  width: 100%;
  border-radius: 20px;
}
@media screen and (max-width: 820px) {
  .foodContainer {
    flex-direction: column;
  }
}
.foodContainer .foodColumnThin {
  border-radius: 20px;
  margin: 8px;
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  flex-direction: column;
  background-color: #F7F4E7;
  max-height: 90vh;
  width: 15vw;
  align-items: center;
  overflow-x: none;
}
@media screen and (max-width: 820px) {
  .foodContainer .foodColumnThin {
    width: 90vw;
  }
}
.foodContainer .foodColumnThin h2 {
  color: #47908A;
  text-decoration: underline;
}
.foodContainer .foodColumnThin .foodList {
  overflow-y: auto;
  overflow-x: none;
}
.foodContainer .foodColumnThin .croffleBox {
  font-family: 'Lexend Deca';
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  margin: 10px;
  max-width: 25vw;
  height: auto;
  border-radius: 20px;
  color: #F7F4E7;
  position: relative;
  text-align: center;
  font-size: 20px;
  min-width: 44%;
  background-color: rgba(71, 144, 138, 0.2);
  overflow: hidden;
}
.foodContainer .foodColumnThin .croffleBox .croffleInfo {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.foodContainer .foodColumnThin .croffleBox .croffleInfo .packingLabel {
  color: #47908A;
  background-color: rgba(0, 0, 0, 0) !important;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.foodContainer .foodColumnThin .croffleBox .croffleInfo .packingLabel .orderType {
  color: #FFF;
  padding: 5px;
  text-align: center;
  font-size: 13px;
}
.foodContainer .foodColumnThin .croffleBox .croffleInfo .packingLabel .orderNo {
  color: #FFF;
  background-color: #e67e22;
  padding: 5px;
  text-align: center;
  font-size: 13px;
}
.foodContainer .foodColumnThin .croffleBox .croffleInfo .packingLabel .orderTime {
  background-color: #F7F4E7;
  font-size: 13px;
  color: #47908A;
  padding: 5px;
  margin: 2px;
  text-align: center;
}
.foodContainer .foodColumnThin .croffleBox .croffleLabel {
  color: #F7F4E7;
  font-size: 1em;
  text-align: center;
  word-break: break-all;
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  padding: 20px;
}
.foodContainer .foodColumnThin .croffleBox .actionBtn {
  width: 100%;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  background-color: #47908A;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #F7F4E7;
  cursor: pointer;
}
.foodContainer .foodColumnThin .croffleBox .actionBtn:hover {
  opacity: 0.5;
}
.foodContainer .crofflesColumn {
  border-radius: 20px;
  margin: 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: #F7F4E7;
  max-height: 90vh;
  width: 60vw;
  align-items: center;
  overflow-x: none;
}
@media screen and (max-width: 820px) {
  .foodContainer .crofflesColumn {
    width: 90vw;
  }
}
.foodContainer .crofflesColumn h2 {
  color: #47908A;
  text-decoration: underline;
}
.foodContainer .crofflesColumn .foodList {
  overflow-y: auto;
  overflow-x: none;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-grow: 1;
  justify-content: center;
}
.foodContainer .crofflesColumn .croffleBox {
  font-family: 'Lexend Deca';
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  margin: 10px;
  max-width: 25vw;
  height: auto;
  border-radius: 20px;
  color: #F7F4E7;
  position: relative;
  text-align: center;
  font-size: 20px;
  min-width: 44%;
  background-color: rgba(71, 144, 138, 0.2);
  overflow: hidden;
}
.foodContainer .crofflesColumn .croffleBox .snackOptionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.foodContainer .crofflesColumn .croffleBox .snackOptionsContainer > div {
  width: 85%;
  background-color: rgba(44, 58, 71, 0.8);
  border-radius: 6px;
  padding: 10px;
  margin: 5px;
  font-size: 16px;
  text-align: left;
}
.foodContainer .crofflesColumn .croffleBox .croffleInfo {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.foodContainer .crofflesColumn .croffleBox .croffleInfo .packingLabel {
  color: #47908A;
  background-color: rgba(71, 144, 138, 0.2) !important;
  width: 100%;
  padding: 3%;
  text-align: left;
  display: flex;
  justify-content: space-around;
}
.foodContainer .crofflesColumn .croffleBox .croffleInfo .packingLabel .orderType {
  color: #FFF;
  padding: 5px;
  border-radius: 6px;
  font-size: 11px;
}
.foodContainer .crofflesColumn .croffleBox .croffleInfo .packingLabel .orderNo {
  color: #FFF;
  background-color: #e67e22;
  padding: 5px;
  border-radius: 6px;
  font-size: 11px;
}
.foodContainer .crofflesColumn .croffleBox .croffleInfo .packingLabel .orderTime {
  background-color: #F7F4E7;
  color: #47908A;
  font-size: 11px;
  border-radius: 6px;
  padding: 5px;
}
.foodContainer .crofflesColumn .croffleBox .croffleInfo .packingLabel .snackLabel {
  color: #FFF;
  background-color: #e67e22;
  padding: 5px;
  border-radius: 6px;
  margin-left: 5px;
}
.foodContainer .crofflesColumn .croffleBox .croffleLabel {
  color: #F7F4E7;
  font-size: 1em;
  text-align: center;
  word-break: break-all;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
}
.foodContainer .crofflesColumn .croffleBox .croffleLabel .imgIcon {
  width: 50px;
  border-radius: 50%;
  padding-right: 5px;
}
.foodContainer .crofflesColumn .croffleBox .croffleLabel .snackLabel {
  color: #e67e22;
  background-color: #FFF;
  padding: 5px;
  border-radius: 6px;
  margin-bottom: 5px;
}
.foodContainer .crofflesColumn .croffleBox .actionBtn {
  width: 100%;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  background-color: #47908A;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #F7F4E7;
  cursor: pointer;
}
.foodContainer .crofflesColumn .croffleBox .actionBtn:hover {
  opacity: 0.5;
}
