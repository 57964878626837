.matcha {
  background-color: #485731;
}
.taro {
  background-color: #634C91;
}
.classic {
  background-color: #3C3333;
}
.caramel {
  background-color: #875020;
}
.blossom {
  background-color: #C44191;
}
.ivorySilk {
  background-color: #D2BBA2;
}
.sparkle {
  background-color: #D73663;
}
.chicken {
  background-color: #C0392B;
}
.berrymerry {
  background: #c23119;
  background: linear-gradient(90deg, #c23119 22%, #485731 54%);
}
.thai {
  background-color: #CA8346;
}
.kokocaphe {
  background-color: #756E30;
}
.jamong {
  background-color: #FF4D4D;
}
.general {
  background-color: #222222;
}
.generalItem {
  background-color: #C1A97E;
}
@keyframes rotateInCircle {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(90);
  }
  66% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes shakeItUp {
  33% {
    transform: rotate(-15deg);
  }
  66% {
    transform: rotate(35deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
@keyframes upDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInHeaderBackground {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: #000000;
  }
}
@keyframes fadeOutHeaderBackground {
  0% {
    background-color: #000000;
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}
.headerContainer {
  width: 100%;
  position: relative;
  height: 120px;
  background-color: #91BCB9;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.headerContainer h2 {
  color: #F3F1E4;
  width: 50%;
  text-transform: uppercase;
}
.headerContainer p {
  color: #47908A;
  font-weight: bold;
  font: 'Lexend Deca';
}
@media screen and (max-width: 800px) {
  .headerContainer {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: unset;
  }
}
.headerContainer .headerBtn {
  height: 20px;
  font-size: 1em;
  background: #F3F1E4;
  color: #47908A;
  border-radius: 1.2em;
  padding: 1.1em;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerContainer .headerBtn:hover {
  opacity: 0.5;
  cursor: pointer;
}
.headerContainer .headerBtn .btnIcon {
  font-size: 1.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
@media screen and (max-width: 800px) {
  .headerContainer .headerBtn {
    width: 80%;
    margin-top: 20px;
  }
}
.headerContainer .logOutBtn {
  text-transform: uppercase;
  font-weight: bold;
  max-width: 200px;
  text-align: center;
  float: right;
}
@media screen and (max-width: 640px) {
  .headerContainer .logOutBtn {
    max-width: unset;
    position: relative;
    margin-bottom: 20px;
  }
}
.welcomeMsg {
  background-color: #F3F1E4;
  color: #47908A;
  padding: 1em;
  border-radius: 6px;
  font-weight: bold;
  max-width: 200px;
  margin: auto;
  margin-bottom: 1em;
  width: 100%;
}
