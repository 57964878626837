.matcha {
  background-color: #485731;
}
.taro {
  background-color: #634C91;
}
.classic {
  background-color: #3C3333;
}
.caramel {
  background-color: #875020;
}
.blossom {
  background-color: #C44191;
}
.ivorySilk {
  background-color: #D2BBA2;
}
.sparkle {
  background-color: #D73663;
}
.chicken {
  background-color: #C0392B;
}
.berrymerry {
  background: #c23119;
  background: linear-gradient(90deg, #c23119 22%, #485731 54%);
}
.thai {
  background-color: #CA8346;
}
.kokocaphe {
  background-color: #756E30;
}
.jamong {
  background-color: #FF4D4D;
}
.general {
  background-color: #222222;
}
.generalItem {
  background-color: #C1A97E;
}
@keyframes rotateInCircle {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(90);
  }
  66% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes shakeItUp {
  33% {
    transform: rotate(-15deg);
  }
  66% {
    transform: rotate(35deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
@keyframes upDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInHeaderBackground {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: #000000;
  }
}
@keyframes fadeOutHeaderBackground {
  0% {
    background-color: #000000;
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100vw;
}
body {
  user-select: none;
  background-color: #91BCB9;
  font-family: 'Lexend Exa', sans-serif;
  overflow-x: hidden!important;
}
.dateInput {
  appearance: none;
  background-color: #FFF;
  outline: none;
  border: none;
  border-color: #47908A;
  border-radius: 6px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  margin: 10px;
  text-align: center;
  max-width: 250px;
  font-family: 'Lexend Exa', sans-serif;
  font-weight: bold;
  color: #47908A;
}
@media screen and (max-width: 800px) {
  .dateInput {
    margin: auto;
    margin-bottom: 1em;
  }
}
.gotActionBtn {
  background-color: #B5A47F;
  padding: 1em;
  border-radius: 36px;
  font-size: 18px;
  max-width: 280px;
  font-family: 'Lexend Exa', sans-serif;
  font-weight: bold;
  color: #F3F1E4;
  text-decoration: none;
}
.gotActionBtn:hover {
  opacity: 0.7;
  cursor: pointer;
}
.disabledField {
  pointer-events: none;
  opacity: 0.7;
}
@font-face {
  font-family: 'BNBergen-Light';
  src: url('../fonts/BNBergen-Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BNBergen';
  src: url('../fonts/BNBergen.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BNBergen-Medium';
  src: url('../fonts/BNBergen-Medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BNBergen-Bold';
  src: url('../fonts/BNBergen-Bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.disabledBtn {
  user-select: none;
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}
.paragraphText {
  font-family: Helvetica, Arial, sans-serif;
}
.titleText {
  font-weight: 600;
  text-transform: uppercase;
}
.hide {
  display: none !important;
}
.innerPageContainer {
  padding-top: 78px;
  width: 100%;
}
.contentWrapper {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 200px);
  overflow-x: hidden;
}
.btnTransparent {
  background-color: rgba(0, 0, 0, 0);
  color: #F3F1E4;
  font-size: 1.3em;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
}
.btnTransparent:hover {
  color: #222222;
  cursor: pointer;
  background-color: #F3F1E4;
}
.btnTransparent .rightArrow {
  padding-left: 10px;
}
/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 820px) {
  .contentWrapper {
    min-height: calc(100vh - 185px);
  }
  .innerPageContainer {
    padding-top: 60px;
  }
}
